import React from 'react';
import { Layout } from '../components/Layout';
import { graphql, Link, PageProps } from 'gatsby';
import kebabCase from 'lodash.kebabcase';
import { IPostEdge } from '../dto/IPost';
import { PostListing } from '../components/PostListing';
import { capitalizeFirstLetter, convertPostEdgeToPost } from '../utils';

const Pill = ({ link, name }: { link: string; name: string }) => (
  <Link
    to={link}
    className="flex items-center justify-center px-4 py-2 bg-slate-900 hover:bg-slate-700 rounded-full text-sm font-semibold text-gray-700"
  >
    <span className="text-blue-500">{capitalizeFirstLetter(name)}</span>
  </Link>
);

interface IListingPageProps extends PageProps {
  pageContext: {
    tag?: string;
    tags?: string[];
  };
}

interface IProps extends IListingPageProps {
  data: {
    allMdx: {
      edges: IPostEdge[];
    };
  };
}

const AllPosts = (props: IProps) => {
  const {
    data,
    pageContext: { tag, tags },
  } = props;
  const posts = data?.allMdx?.edges;
  const postsGroupedByYear = posts?.reduce((acc, post) => {
    const year = new Date(post.node.frontmatter.date).getFullYear();
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(post);
    return acc;
  }, {} as { [key: string]: IPostEdge[] });

  return (
    <Layout title={tag ? `${capitalizeFirstLetter(tag)} articles` : 'All posts'}>
      <div className="flex flex-col py-32 px-4 gap-16">
        <div className="article flex flex-col text-center">
          <h1>
            {tag ? (
              <>
                <span className="text-blue-500">{capitalizeFirstLetter(tag)}</span> articles
              </>
            ) : (
              'All articles ✍️'
            )}
          </h1>
          <p>{posts.length} articles</p>
        </div>
        <div className="flex gap-4 m-auto flex-wrap px-16 justify-center">
          {!!tags?.length &&
            tags.map((tag) => <Pill name={tag} link={`/tags/${kebabCase(tag)}`} key={tag} />)}
          {tag && <Pill name="All articles" link="/blog/" />}
        </div>
        <div className="max-w-7xl px-4 lg:px-8 space-y-16 m-auto">
          {Object.keys(postsGroupedByYear)
            // @ts-ignore
            .sort((a: string, b: string) => b - a)
            .map((year) => (
              <div key={year}>
                <h2 className="text-4xl mb-6 font-bold text-slate-300 text-center">{year}</h2>
                <div className="flex flex-col gap-4">
                  {postsGroupedByYear[year].map((post) => (
                    <PostListing className="mx-auto w-full" post={convertPostEdgeToPost(post)} />
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </Layout>
  );
};

export default AllPosts;

/* eslint no-undef: "off" */
export const allPostsQuery = graphql`
  query ListingQuery($tag: String, $currentDate: Date!) {
    allMdx(
      sort: { fields: [fields___date], order: DESC }
      filter: {
        fields: { sourceInstanceName: { eq: "posts" } }
        frontmatter: { date: { lte: $currentDate }, tags: { eq: $tag } }
      }
    ) {
      edges {
        node {
          ...postNodeFragment
        }
      }
    }
  }
`;
